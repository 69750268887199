import axios from 'axios';
let baseUrl = '';
if (process.env.NODE_ENV === 'production') {
  baseUrl = '/api';
} else {
  baseUrl = 'http://portfolio.local/api';
}

const ajax = axios.create({
    baseURL: baseUrl,
    headers: {
        "content-type": "application/json"
    },
});

ajax.CancelToken = axios.CancelToken;
ajax.isCancel = axios.isCancel;

ajax.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config
    },
    () => {
        return false;
    }
);


ajax.interceptors.response.use(
    (response) => {
        if (response.data.code !== undefined && response.data.code === "401") {
            return false;
        }
        return response;
    },
    () => {
        // The request was made and the server responded with a status code
        return false;
    }
);

export default ajax