import ajax from "@/plugins/api";
//import app from '@/main';

function checkExistUser(param, type) {
    let url;
    if (type === 'base' || type === 'gmail') {
        url = '/user-exist?email=' + param;
    } else {
        url = '/user-exist?telegram=' + param;
    }
    url += '&type=' + type;
    return ajax.get(url).then(response => {
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function getUsers() {
    return ajax.get('/users', {}).then(response => {
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function deleteUser(userID) {
    return ajax.post('/delete-user', {
        'userID': userID,
    }).then(response => {
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function loginUser(email, password) {
    return ajax.post('/login', {email: email, password: password}).then(response => {
        if (response.data.success) {
            setLoginData(response.data);
        }
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function changePassword(oldPassword, newPassword) {
    return ajax.post('/change-password', {oldPassword: oldPassword, newPassword: newPassword}).then(response => {
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function forgotPassword(email) {
    return ajax.post('/forgot-password', {email: email}).then(response => {
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function resetPassword(token, password) {
    return ajax.post('/reset-password', {'token': token, 'password': password}).then(response => {
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function loginGmail(email, accessToken) {
    return ajax.post('/login-gmail', {'email': email, 'token': accessToken}).then(response => {
        if (response.data.success) {
            setLoginData(response.data)
        }
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function loggedUser() {
    const item = localStorage.getItem('token');
    if (item == null) {
        return { success: false };
    }
    return ajax.get('/logged-user',).then(response => {
        if(item !== null && response === false){
            localStorage.removeItem('token');
        }
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function setLoginData(data) {
    const item = localStorage.getItem('token');
    if (item !== null) {
        localStorage.removeItem('token');
    }
    const user = localStorage.getItem('user');
    if (user !== null) {
        localStorage.removeItem('user');
    }
    localStorage.setItem('user', data.user.email);
    localStorage.setItem('token', data.token);
}

function loginTelegram(username, hash, first_name, last_name, id, auth_date) {
    return ajax.post('/login-telegram', {
        'first_name': first_name,
        'last_name': last_name,
        'telegram_id': id,
        'auth_date': auth_date,
        'username': username,
        'hash': hash
    }).then(response => {
        if (response.data.success) {
            setLoginData(response.data)
        }
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function test() {
    return ajax.get('/test').then(response => {
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function getMoreUsers(url) {
    return ajax.get(url).then(response => {
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function isAdmin() {
    return ajax.get('/is-admin').then(response => {
        return response.data.admin;
    }).catch(() => {
            return false;
        }
    )
}

export {
    getUsers,
    deleteUser,
    loginUser,
    changePassword,
    forgotPassword,
    resetPassword,
    loginGmail,
    loginTelegram,
    loggedUser,
    checkExistUser,
    getMoreUsers,
    isAdmin,
    test
}