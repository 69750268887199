<template>
    <component :is="currentComponent" :userName="userName" :isLogged="isLogged" :subscriptionHide="subscriptionHide"
               :subscription="Boolean(subscription)" @update-subscription="handleUpdateSubscription"></component>
    <router-view></router-view>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import AdminNavigation from "./components/AdminNavigation.vue";
import {loggedUser} from "@/plugins/common/user";

export default {
    name: 'App',
    components: {
        Navigation,
        AdminNavigation
    },
    data() {
        return {
            userName: '',
            isLogged: false,
            subscriptionHide: false,
            subscription: false,
        };
    },
    computed: {
        currentComponent() {
            return this.$route.meta.showAdminNavigation ? 'AdminNavigation' : 'Navigation';
        }
    },
    beforeMount() {
        this.isUserLogged();
        document.addEventListener("contextmenu", this.disableRightClick);
    },
    watch: {
        '$route'() {
            this.isUserLogged();
        }
    },
    beforeUnmount() {
        // Remove the global listener on unmount
        document.removeEventListener("contextmenu", this.disableRightClick);
    },
    methods: {
        isUserLogged() {
            if(this.$route.name === undefined){
                this.resetUserState();
                return;
            }
            const token = localStorage.getItem('token');
            if (token !== null) {
                loggedUser().then(response => {
                    if (response.success) {
                        this.isLogged = true;
                        this.userName = response.user.email;
                        this.subscriptionHide = response.subscription?.type === 'one-time';
                        this.subscription = response.user.subscription || false;
                        this.hasSubscription.value = response.user.subscription === 1;
                    } else {
                        this.resetUserState();
                    }
                }).catch(() => this.resetUserState());
            } else {
                this.resetUserState();
            }
        },
        resetUserState() {
            this.isLogged = false;
            this.userName = '';
            this.subscription = false;
        },
        handleUpdateSubscription(newSubscription) {
            this.subscription = newSubscription;
            this.subscriptionHide = newSubscription;
        },
        disableRightClick(event) {
            event.preventDefault(); // Prevent the context menu from appearing
        },
    }
}
</script>
