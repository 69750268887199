<template>
    <div v-if="$route.meta.showNavigation !== true" class="nav-box">
        <a href="/collections">
            <img src="@/assets/images/logo.jpg" alt="logo">
        </a>
        <RouterLink
            to="/collections"
            activeClass="active-link"
            exactActiveClass="nonactive-link"
            :class="{ 'active-link': isActiveCollections }">
            <p>Collections</p>
        </RouterLink>
        <RouterLink
            to="/goddesses"
            activeClass="active-link"
            exactActiveClass="nonactive-link"
            :class="{ 'active-link': isActiveGoddesses }">
            <p>Goddesses</p>
        </RouterLink>
        <RouterLink
            to="/contactme"
            activeClass="active-link"
            exactActiveClass="nonactive-link">
            <p>Contact me</p>
        </RouterLink>

        <div class="nav-right-buttons-box">
            <button class="search-button button" @click="redirectSearch"><i class="bi bi-search"></i></button>
            <a v-if="!isLogged" href="/login" class="button">Log in</a>
            <div v-else>
                <button class="nav-show-settings" @click="showSettingsBox = !showSettingsBox">{{ this.shortEmail }}
                    <i class="bi bi-caret-down"></i>
                </button>
                <div v-if="showSettingsBox" ref="settingsBox" class="nav-settings-box animate__animated animate__fadeIn animate__faster">
                    <p @click="visibleModal = !visibleModal">Change password</p>
                    <p v-if="!subscription" @click="purchaseSubscription">Purchase subscription</p>
                    <p v-else @click="cancelSubscription">Cancel subscription</p>
                    <p><a href="/logout">Log out</a></p>
                </div>
            </div>
        </div>
    </div>
    <div v-if="$route.meta.showNavigation !== true" class="nav-box responsive-nav-box">
        <a href="/collections">
            <img src="@/assets/images/logo.jpg" alt="logo">
        </a>
        <div class="nav-right-buttons-box">
            <button class="search-button button" @click="redirectSearch"><i class="bi bi-search"></i></button>
            <button class="burger-button" @click="openNav"><i class="bi bi-list"></i></button>
        </div>
        <div id="nav-box-responsive" class="overlay">
            <a href="javascript:void(0)" class="closebtn" @click="closeNav"><i class="bi bi-x-lg"></i></a>

            <div class="overlay-content">
                <p @click="redirect('/collections')">Collections</p>
                <p @click="redirect('/goddesses')">Goddesses</p>
                <p @click="redirect('/contactme')">Contact me</p>
                <p v-if="!isLogged" @click="redirect('/login')">Log in</p>
                <div v-else>
                    <p @click="() => { visibleModal = !visibleModal; closeNav() }">Change password</p>
                    <p v-if="!subscription" @click="() => { purchaseSubscription(); closeNav() }">Purchase subscription</p>
                    <p v-else @click="() => { cancelSubscription(); closeNav() }">Cancel subscription</p>
                    <p><a href="/logout">Log out</a></p>
                </div>
            </div>
        </div>
    </div>

    <div v-else>
        <img class="main-logo" src="@/assets/images/logo.jpg" alt="logo">
        <div class="main-nav-box">
            <h2>
                <RouterLink
                    to="/collections"
                    activeClass="active-link"
                    exactActiveClass="nonactive-link">
                    Collections
                </RouterLink>
            </h2>
            <h2>
                <RouterLink
                    to="/goddesses"
                    activeClass="active-link"
                    exactActiveClass="nonactive-link">
                    Goddesses
                </RouterLink>
            </h2>
            <h2>
                <RouterLink
                    to="/contactme"
                    activeClass="active-link"
                    exactActiveClass="nonactive-link">
                    Contact me
                </RouterLink>
            </h2>
            <div class="nav-right-buttons-box logged-user">
                <button class="search-button button" @click="redirectSearch"><i class="bi bi-search"></i></button>
                <a v-if="!isLogged" href="/login" class="main-login-button">Log in</a>
                <div v-else>
                    <button class="nav-show-settings" @click="showSettingsBox = !showSettingsBox">{{ this.shortEmail }}
                        <i class="bi bi-caret-down"></i>
                    </button>
                    <div v-if="showSettingsBox"
                         class="nav-settings-box animate__animated animate__fadeIn animate__faster">
                        <p @click="visibleModal = !visibleModal">Change password</p>
                        <div v-if="!subscriptionHide">
                            <p v-if="!subscription" @click="purchaseSubscription">Purchase subscription</p>
                            <p v-else @click="cancelSubscription">Cancel subscription</p>
                        </div>
                        <p><a href="/logout">Log out</a></p>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <modal v-model:visible="visibleModal"></modal>
</template>

<script>
import Modal from '@/components/ChangePassword.vue';
import Swal from 'sweetalert2';
import {cancelSubscription} from "@/plugins/requests";


export default {
    name: 'Navigation',
    data() {
        return {
            showSettingsBox: false,
            visibleModal: false,
            shortEmail: '',
            swal: Swal.mixin({
                confirmButtonText: 'Confirm',
                showCloseButton: true,
                customClass: {
                    confirmButton: 'button',
                },
                background: '#1E1E1E',
                color: '#FFFFFF',
                showClass: {
                    popup: `
                    animate__animated
                    animate__zoomIn
                    animate__faster
                    `
                },
            }),
            toast: Swal.mixin({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                background: '#1E1E1E',
                color: '#FFFFFF',
                showClass: {
                    popup: `
                    animate__animated
                    animate__slideInRight
                    animate__faster
                    `
                },
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            })
        }
    },
    emits: ['update-subscription'],
    props: {
        userName: {
            type: String,
            default: ''
        },
        isLogged: {
            type: Boolean,
            default: false
        },
        subscription: {
            type: Boolean,
            default: false
        },
        subscriptionHide: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Modal
    },
    computed: {
        isActiveGoddesses() {
            return this.$route.path.startsWith('/goddesses');
        },
        isActiveCollections() {
            return this.$route.path.startsWith('/collections');
        },
        
    },
    watch: {
        userName: 'shortenEmail'
    },
    mounted() {
        this.shortenEmail();
    },
    methods: {
        cancelSubscription() {
            this.swal.fire({
                title: 'Cancel subscription',
                text: 'Access to all premium photos will be cancelled immediately'
            }).then((result) => {
                if (result.isConfirmed) {
                    cancelSubscription().then(response => {
                        if (response.success) {
                            this.showSettingsBox = false;
                            this.hasSubscription.value = false;
                            this.$emit('update-subscription', false);
                            this.toast.fire({
                                icon: "success",
                                title: "Subscription is cancelled successfully!"
                            });
                        } else {
                            this.toast.fire({
                                icon: "error",
                                title: "Something went wrong"
                            });
                        }
                    }).catch(() => {
                        this.toast.fire({
                            icon: "error",
                            title: "Something went wrong"
                        });
                    });
                }
            });
        },
        purchaseSubscription() {
            this.showSettingsBox = false;
            localStorage.setItem('reg-type', 'none');
            this.$router.push("/subscription");
        },
        redirectSearch() {
            this.$router.push("/search");
        },
        shortenEmail() {
            if (this.userName <= 15) {
                this.shortEmail = this.userName;
            }

            const [username, domain] = this.userName.split("@");
            const shortUsername = username.length > 6 ? username.slice(0, 6) + "..." : username;
            
            this.shortEmail = shortUsername + "@" + domain;
        },
        openNav() {
            document.getElementById("nav-box-responsive").style.height = "100%";
        },
        closeNav() {
            document.getElementById("nav-box-responsive").style.height = "0%";
        },
        redirect(path) {
            this.closeNav();
            this.$router.push(path);
        }
    }
}
</script>

<style scoped>
.nav-settings-box {
    top: 60px;
}

.nav-settings-box p {
    margin: 20px;
    cursor: pointer;
}

.nav-show-settings {
    position: unset;
}

.main-login-button {
    position: unset;
}
</style>