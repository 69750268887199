<template>
    <div class="nav-box">
        <a href="/collections">
            <img src="@/assets/images/logo.jpg" alt="logo">
        </a>
        <RouterLink
            to="/admin/collections/0"
            activeClass="active-link"
            exactActiveClass="nonactive-link">
            <p>Collections</p>
        </RouterLink>
        <RouterLink
            to="/admin/goddesses"
            activeClass="active-link"
            exactActiveClass="nonactive-link">
            <p>Goddesses</p>
        </RouterLink>
        <RouterLink
            to="/admin/users"
            activeClass="active-link"
            exactActiveClass="nonactive-link">
            <p>Users</p>
        </RouterLink>
        <RouterLink
            to="/admin/settings"
            activeClass="active-link"
            exactActiveClass="nonactive-link">
            <p>Main settings</p>
        </RouterLink>
        <button class="nav-show-settings" @click="showSettingsBox = !showSettingsBox">Administrator <i
            class="bi bi-caret-down"></i></button>
        <div v-if="showSettingsBox" class="nav-settings-box animate__animated animate__fadeIn animate__faster">
            <p @click="visibleModal = !visibleModal">Change password</p>
            <p><a href="/logout">Log out</a></p>
        </div>
    </div>
    <div class="nav-box responsive-nav-box">
        <img src="@/assets/images/logo.jpg" alt="logo">
        <div class="nav-right-buttons-box">
            <button class="burger-button" @click="openNav"><i class="bi bi-list"></i></button>
        </div>
        <div id="nav-box-responsive" class="overlay">
            <a href="javascript:void(0)" class="closebtn" @click="closeNav"><i class="bi bi-x-lg"></i></a>

            <div class="overlay-content">
                <p @click="redirect('/admin/goddesses')">Goddesses</p>
                <p @click="redirect('/admin/collections/0')">Collections</p>
                <p @click="redirect('/admin/users')">Users</p>
                <p @click="redirect('/admin/settings')">Main settings</p>
                <p @click="() => { visibleModal = !visibleModal; closeNav() }">Change password</p>
                <p><a href="/logout">Log out</a></p>
            </div>
        </div>
    </div>

    <modal v-model:visible="visibleModal"></modal>

</template>

<script>
import Modal from '@/components/ChangePassword.vue';
import router from "@/router";

export default {
    name: 'Admin navigation',
    components: {
        Modal
    },
    props: {
        userName: String,
        isLogged: Boolean,
        subscriptionHide: Boolean,
        subscription: Boolean
    },
    emits: ['update-subscription'],
    data() {
        return {
            showSettingsBox: false,
            visibleModal: false,
        }
    },
    methods: {
        logout() {
            const item = localStorage.getItem('token');
            if (item !== null) {
                localStorage.removeItem('token');
            }

            const user = localStorage.getItem('user');
            if (user !== null) {
                localStorage.removeItem('user');
            }

            const ad = localStorage.getItem('ad');
            if (ad !== null) {
                localStorage.removeItem('ad');
            }
            this.hasSubscription.value = false;
            router.push('/');
        },
        openNav() {
            document.getElementById("nav-box-responsive").style.height = "100%";
        },
        closeNav() {
            document.getElementById("nav-box-responsive").style.height = "0%";
        },
        redirect(path) {
            this.closeNav();
            router.push(path);
        }
    }
}
</script>